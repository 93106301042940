






















































import { defineComponent, reactive, ref } from '@vue/composition-api'
import axios from '@/service/index'
import { getToken } from '@/utils'

export interface MsgListItem {
  role: string
  content: string
}
export default defineComponent({
  name: 'MobileDialog',
  setup() {
    const showMsg = reactive<Array<MsgListItem>>([
      {
        role: 'robot',
        content: '你好，我是AI小助手，有什么可以帮助您的吗？',
      },
    ])
    const currentMsg = ref('')
    const questionEmpty = ref(false)
    const isUser = ref(false)
    const messageSumbit = async () => {
      if (currentMsg.value) {
        showMsg.push({
          role: 'user',
          content: currentMsg.value,
        })
        const {
          data: { data },
        } = await axios.get(
          `http://47.110.126.186:3010/find/getAnswer?question=${currentMsg.value}`,
          {
            headers: {
              Authorization: `${getToken()}`,
            },
          },
        )
        const res: MsgListItem = {
          role: data?.business || 'robot',
          content: data?.answer || '抱歉，未查到答案',
        }
        showMsg.push(res)
        currentMsg.value = ''
      } else {
        questionEmpty.value = true
      }
    }
    return {
      showMsg,
      currentMsg,
      messageSumbit,
      questionEmpty,
      isUser,
    }
  },
})
